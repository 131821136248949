import Hammer from 'hammerjs';
import { fetchSwiper } from '../dynamic-modules';
import { Sketch } from './sketch.js';
import { webglObserver } from '../utils/execute-on-intersection';

let webglSketch: any;

export default function webglEffect(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-info-slider')).forEach(async (el) => {
        const titleSlider = el?.querySelector<HTMLElement>('.js-webgl-title-slider');
        const { Swiper } = await fetchSwiper();

        if (!el.classList.contains('is-initialized-slider')) {
            webglObserver(
                el,
                webglSketch,
                () => {
                    if (titleSlider) {
                        new Swiper(titleSlider, {
                            slidesPerView: 1.2,
                            speed: 800,
                            loop: true,
                            spaceBetween: 10,
                            centeredSlides: true,
                            slideActiveClass: 'is-active',
                            allowTouchMove: false,
                            breakpoints: {
                                957: {
                                    slidesPerView: 1.9,
                                }
                            }
                        })
                    }

                    webglSketch = new Sketch({
                        selector: '.js-info-slider',
                        easing: 'easeOut',
                        uniforms: {
                            width: {value: 0.5, type:'f', min:0, max:10},
                        }
                    });

                    const hammertime = new Hammer(document.querySelector('.js-info-slider'));
                    hammertime.on('swipe', function(event: Event) {
                        const target = event as any;
                        target.direction === 2 ? webglSketch.next() : webglSketch.prev();
                    });

                    el.classList.add('is-initialized-slider')
                },
                { rootMargin: '200px 0px 200px 0px' },
            );
        }
    });
}


