import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

let mainSlider: HTMLElement | null,
    popupSlider: HTMLElement | null | undefined;

function setSlide(this: LitPopupElement) {
    if (mainSlider && popupSlider && mainSlider.swiper && popupSlider.swiper) {
        const mainIndex = mainSlider.swiper.realIndex;
        popupSlider.swiper.slideToLoop(mainIndex, 0);
    }
}

function setMainSlide(this: LitPopupElement) {
    if (mainSlider && popupSlider && mainSlider.swiper && popupSlider.swiper) {
        const popupIndex = popupSlider.swiper.realIndex;
        mainSlider.swiper.slideToLoop(popupIndex, 0);
    }
}

function init(container: HTMLElement | Document = document) {
    const popupGallery = container.querySelector<LitPopupElement>('.js-gallery-popup');
    mainSlider = document.querySelector<HTMLElement>('.js-gallery-slider-main');
    popupSlider = popupGallery?.querySelector<HTMLElement>('.js-gallery-slider-popup');

    popupGallery?.addEventListener('open', setSlide);
    popupGallery?.addEventListener('close', setMainSlide);
}

const _module = { init };

export default _module;
