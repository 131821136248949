import { TimelineMax, Power2 } from 'gsap/gsap-core';
import {
    PerspectiveCamera,
    WebGLRenderer,
    Scene,
    Mesh,
    LoadingManager,
    TextureLoader,
    ShaderMaterial,
    Vector4,
    PlaneGeometry,
} from 'three';

export class Sketch {
    constructor(opts) {
        this.vertex = `varying vec2 vUv;void main() {vUv = uv;gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );}`;
        this.fragment = fragment;
        this.uniforms = opts.uniforms;
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.duration = opts.duration || 2;
        this.easing = opts.easing || "easeInOut";
        this.autoPlay = opts.autoplay || false;
        this.timeInterval = null;
        this.hovered = opts.hovered || false;
        this.scene = new Scene();
        this.renderer = new WebGLRenderer();
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.renderer.setSize(this.width, this.height);
        this.renderer.setClearColor(0xeeeeee, 1);

        this.selector = opts.selector || "#slider";
        this.container = opts.element ? opts.element : document.querySelector(this.selector);
        this.imagesContainer = this.container?.querySelector('.js-webgl-effect');
        this.titleBlock = this.container?.querySelector('.js-webgl-title-slider');

        this.navigation = this.container?.querySelector(".js-webgl-slide-navigation");
        this.nextSlide = this.container?.querySelector(".js-webgl-slide-next");
        this.prevSlide = this.container?.querySelector(".js-webgl-slide-prev");
        this.contentArr = Array.from(this.container?.querySelectorAll(".js-webgl-slide-content"));
        this.paginationContainer = this.container?.querySelector(".js-webgl-slide-pagination-container");
        this.paginations;

        if (this.imagesContainer) {
            this.images = this.imagesContainer.querySelectorAll(".js-webgl-img");
            this.width = this.imagesContainer?.offsetWidth;
            this.height = this.imagesContainer?.offsetHeight;
            this.imagesContainer?.appendChild(this.renderer.domElement);
        }

        this.camera = new PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            0.1,
            100
        );

        this.camera.position.set(0, 0, 2);
        this.time = 0;
        this.current = 0;
        this.textures = [];

        this.paused = true;
        this.initiate(() => {
            this.current = 0;
            this.setupResize();
            this.addObjects();
            this.resize();
            this.clickEvent();
            this.play();
            this.setNavigation(this.current);
            this.setContent(this.current);

            if (this.images && this.images.length > 1 && this.autoPlay) {
                this.intervalAnimation();
            } else if (!this.images || this.images.length <= 1) {
                if (this.navigation) {
                    this.navigation.style.display = 'none';
                }
                if (this.paginationContainer) {
                    this.paginationContainer.style.display = 'none';
                }

                if (this.nextSlide) {
                    this.nextSlide.style.display = 'none';
                }

                if (this.prevSlide) {
                    this.prevSlide.style.display = 'none';
                }
            }
        });
    }

    initiate(cb) {
        const promises = [];
        const loadingManager = new LoadingManager();
        const textureLoader = new TextureLoader(loadingManager);

        loadingManager.onStart = () => {
            if (this.paginationContainer) {
                this.paginationContainer.innerHTML = '';
            }
        }

        loadingManager.onLoad = () => {
            if (this.paginationContainer) {
                this.images.forEach(image => {
                    this.initNavigation(image.currentSrc);
                });

                this.paginations = document.querySelectorAll(".js-webgl-slide-pagination");
            }
        }

        if (this.images) {
            this.images.forEach((image, i) => {
                const _this = this;
                if (image.classList.contains('lazy')) {
                    if (image.classList.contains('loaded')) {
                        loadTexture();
                    } else {
                        image.addEventListener('loaded', loadTexture)
                    }
                } else {
                    loadTexture();
                }

                function loadTexture() {
                    let promise = new Promise((resolve) => {
                        _this.textures[i] = textureLoader.load(image.currentSrc, resolve);
                    });
                    promises.push(promise);

                    image.removeEventListener('loaded', loadTexture);

                    if (_this.textures.length == _this.images.length) {
                        Promise.all(promises).then(() => {
                            cb();
                        });
                    }
                }
            });
        }
    }

    intervalAnimation() {
        if (this.autoPlay) {
            this.timeInterval = setInterval(() => {
                if (!this.isRunning) {
                    this.next();
                }
            }, 7000);
        }
    }

    initNavigation(url) {
        if (!this.paginationContainer) return;

        const item = document.createElement('button');
        item.classList.add('main-slider__min-item');
        item.classList.add('js-webgl-slide-pagination');
        const img = document.createElement('img');
        item.setAttribute('aria-label', 'change slide');
        img.setAttribute('src', url);
        img.setAttribute('alt', 'mini image');
        img.setAttribute('width', '100');
        img.setAttribute('height', '70');
        img.setAttribute('crossorigin', 'anonymous');
        item.appendChild(img);
        this.paginationContainer.appendChild(item);
    }

    clickEvent() {
        this.nextSlide?.addEventListener("click", () => {
            this.next();
        });
        this.prevSlide?.addEventListener("click", () => {
            this.prev();
        });

        if (this.hovered) {
            this.container.addEventListener("mouseenter", () => this.setHovered());
            this.container.addEventListener("mouseleave", () => this.setHovered());
        }

        this.paginations?.forEach((pagination, index) => {
            pagination?.addEventListener("click", (e) => {
                e.preventDefault();
                this.setSlide(index);
            });
        })
    }

    setupResize() {
        window.addEventListener("resize", this.resize.bind(this));
    }

    resize() {
        this.width = this.imagesContainer?.offsetWidth;
        this.height = this.imagesContainer?.offsetHeight;
        this.renderer.setSize(this.width, this.height);
        this.camera.aspect = this.width / this.height;

        // image cover
        if (this.textures.length > 0) {
            this.imageAspect = this.textures[0].image.height / this.textures[0].image.width;
        }

        let a1;
        let a2;
        if (this.height / this.width > this.imageAspect) {
            a1 = (this.width / this.height) * this.imageAspect;
            a2 = 1;
        } else {
            a1 = 1;
            a2 = this.height / this.width / this.imageAspect;
        }

        this.material.uniforms.resolution.value.x = this.width;
        this.material.uniforms.resolution.value.y = this.height;
        this.material.uniforms.resolution.value.z = a1;
        this.material.uniforms.resolution.value.w = a2;

        const dist = this.camera.position.z;
        const height = 1;
        this.camera.fov = 2 * (180 / Math.PI) * Math.atan(height / (2 * dist));

        this.plane.scale.x = this.camera.aspect;
        this.plane.scale.y = 1;

        this.camera.updateProjectionMatrix();
    }

    addObjects() {
        this.material = new ShaderMaterial({
            uniforms: {
                time: { type: "f", value: 0 },
                progress: { type: "f", value: 0 },
                width: { type: "f", value: 0 },
                texture1: { type: "f", value: this.textures[0] },
                texture2: { type: "f", value: this.textures[1] },
                resolution: { type: "v4", value: new Vector4() },
            },
            vertexShader: this.vertex,
            fragmentShader: this.fragment,
        });

        this.geometry = new PlaneGeometry(1, 1, 2, 2);

        this.plane = new Mesh(this.geometry, this.material);

        this.matrixAutoUpdate = false;
        this.scene.add(this.plane);
    }

    stop() {
        this.paused = true;
    }

    play() {
        this.paused = false;
        this.render();
    }

    setNavigation(currentIndex) {
        if (!this.navigation || !this.images) return;
        this.navigation.textContent = `${currentIndex + 1}/${this.images.length}`
    }

    setHovered() {
        if (!this.isRunning) {
            this.next();
        } else {
            const hoveredInterval = setInterval(() => {
                if (!this.isRunning) {
                    this.next();
                    clearInterval(hoveredInterval);
                }
            }, 300);
        }
    }

    next() {
        if (this.isRunning) return;
        this.isRunning = true;

        if (this.titleBlock && this.titleBlock.swiper) {
            this.titleBlock.swiper.slideNext();
        }
        let length = this.textures.length;
        const newIndex = this.current === length - 1 ? 0 : this.current + 1;
        this.setNextSlide(newIndex);
    }

    prev() {
        if (this.isRunning) return;
        this.isRunning = true;

        if (this.titleBlock && this.titleBlock.swiper) {
            this.titleBlock.swiper.slidePrev();
        }

        let length = this.textures.length;
        const newIndex = this.current === 0 ? length - 1 : this.current - 1;
        this.setNextSlide(newIndex);
    }

    setSlide(index) {
        if (this.isRunning) return;
        this.isRunning = true;
        this.setNextSlide(index);
    }

    setNextSlide(index, duration = this.duration, changeContentAnimation = true) {
        this.isRunning = true;
        let length = this.textures.length;
        let nextTexture = this.textures[index % length];
        this.material.uniforms.texture2.value = nextTexture;
        let tl = new TimelineMax();
        tl.to(this.material.uniforms.progress, duration, {
            value: 1,
            ease: Power2[this.easing],
            onComplete: () => {
                this.current = index % length;
                this.material.uniforms.texture1.value = nextTexture;
                this.material.uniforms.progress.value = 0;
                this.isRunning = false;
            },
        });

        this.setNavigation(index);
        this.setContent(index, changeContentAnimation);
    }

    setContent(index, animation = true) {
        if (!this.contentArr) return;

        const prevEl = this.contentArr.find(content => content.classList.contains('is-show'));

        if (animation) {
            prevEl?.classList.add('is-out');
            setTimeout(() => {
                prevEl?.classList.remove('is-out');
                this.contentArr.forEach(item => {
                    item.classList.remove('is-show');
                })
                if (this.contentArr[index]) {
                    this.contentArr[index].classList.add('is-show');
                }
            }, 400);
        } else {
            this.contentArr.forEach(item => {
                item.classList.remove('is-out');
                item.classList.remove('is-show');
                item.classList.remove('no-animation');
            })
            this.contentArr[index].classList.add('is-show');
        }
    }

    render() {
        if (this.paused) return;
        this.time += 0.05;
        this.material.uniforms.time.value = this.time;

        requestAnimationFrame(this.render.bind(this));
        this.renderer.render(this.scene, this.camera);
    }

    playFirstSlide() {
        this.intervalAnimation();
    }

    setFirstSlide() {
        this.isRunning = false;
        clearInterval(this.timeInterval);
        this.current = 0;
        this.contentArr.forEach(item => item.classList.add('no-animation'));
        this.setNextSlide(this.current, 0, false);
    }

    enable() {
        this.play();
    }

    disable() {
        clearInterval(this.timeInterval);
        this.nextSlide?.removeEventListener("click", this.next);
        this.prevSlide?.removeEventListener("click", this.prev);
        this.paused = true;
    }
}

const fragment = `
    uniform float time;
    uniform float progress;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform vec4 resolution;

    varying vec2 vUv;
    varying vec4 vPosition;

    void main()	{
        vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
        vec2 p = newUV;
        float x = progress;
        x = smoothstep(.0,1.0,(x*2.0+p.y-1.0));
        vec4 f = mix(
            texture2D(texture1, (p-.5)*(1.-x)+.5),
            texture2D(texture2, (p-.5)*x+.5),
            x);
        gl_FragColor = f;
    }
`
