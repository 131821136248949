import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

export function createInitialOrderTimeline() {
    const tl = gsap.timeline({
        paused: true,
        defaults: { duration: 0.9, ease: 'cubic-bezier(.25,.46,.45,.94)' },
        onComplete: () => {
            document.documentElement.classList.add('initial-animation-complete');
        },
    });

    const fitsrtTitle = document.querySelector('.a-order-title');

    let splittedParent, splitted;
    if (fitsrtTitle) {
        splittedParent = new SplitText(fitsrtTitle, {
            type: 'lines',
            linesClass: 'title-line-parent'
        });

        splitted = new SplitText(fitsrtTitle.getElementsByClassName('title-line-parent'), {
            type: "lines",
        });
    }

    if (splitted && fitsrtTitle) {
        tl
            .to('.a-order-title', {
                opacity: 1,
                stagger: 0.3
            }, '-=0.3')
            .fromTo(splitted.lines,
                { yPercent: 100 },
                {
                    yPercent: 0,
                    duration: 0.8,
                    onComplete: function(){
                        splittedParent.revert();
                        splitted.revert();
                    }
                }, '-=0.3'
            )
            .fromTo('.a-order-descr',
                {
                    opacity: 0,
                    y: 20,
                },
                {
                    opacity: 1,
                    y: 0
                }, '-=0.3'
            )
            .to('.a-order-images', {
                opacity: 1,
                stagger: 0.4
            }, '-=0.2');
    }

    return tl;
}
