const map = new WeakMap<HTMLElement, any>();

let startDateInput: HTMLInputElement | null;
let endDateInput: HTMLInputElement | null;
let amountInput: HTMLInputElement | null;
let priceElement: HTMLElement | null;
let pricePerDay: number;
let pricePerWeek: number;
let pricePerMonth: number;

function updateAmountAndPrice() {
    if (startDateInput && endDateInput && priceElement) {
        const startDate = new Date(startDateInput.value);
        const endDate = new Date(endDateInput.value);
        const daysDifference = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

        let totalPrice = 0;
        if (!isNaN(daysDifference) && daysDifference > 0) {
            const dailyPriceFromWeek = pricePerWeek / 7;
            const dailyPriceFromMonth = pricePerMonth / 30;
            if (daysDifference < 7) {
                totalPrice = pricePerDay * daysDifference;
            } else if (daysDifference >= 7 && daysDifference < 30) {
                totalPrice = dailyPriceFromWeek * daysDifference; // Используем цену за день, полученную из недельной ставки
            } else if (daysDifference >= 30) {
                totalPrice = dailyPriceFromMonth * daysDifference; // Используем цену за день, полученную из месячной ставки
            }
            priceElement.textContent = `${totalPrice.toFixed(0)}`;
        } else {
            // Handle invalid date range
            // You might want to display an error message or reset the form
        }
    }
}

function init(container: HTMLElement | Document = document) {
    startDateInput = container.querySelector('.purchase-form__start-date');
    endDateInput = container.querySelector('.purchase-form__end-date');
    priceElement = container.querySelector('.js-submit-btn__price-amount');

    pricePerDay = parseFloat((document.getElementById('price-per-day') as HTMLInputElement)?.value);
    pricePerWeek = parseFloat((document.getElementById('price-per-week') as HTMLInputElement)?.value);
    pricePerMonth = parseFloat((document.getElementById('price-per-month') as HTMLInputElement)?.value);

    startDateInput?.addEventListener('change', updateAmountAndPrice);
    endDateInput?.addEventListener('change', updateAmountAndPrice);
}

function destroy(container: HTMLElement | Document = document) {
    // Очистите любые прослушиватели событий или другие ресурсы при необходимости
}

const _module = { init, destroy };

export default _module;
