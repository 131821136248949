import createValidator from '../../modules/validator';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import { Collapse } from '../custom-elements/Collapse/Collapse';
import {createInitialOrderTimeline} from '../animations/initial-success-order';

const header = document.querySelector<HTMLElement>('.js-header');
let personalInfo: string;
let shippingAddress: string;
let orderContainer: HTMLElement | null;

function getValues(container: HTMLElement) {
    const infoContainer = container.querySelector('.js-info-container');
    const textInfoContainer = infoContainer?.querySelector('.js-info-about-step');
    const inputs = container?.querySelectorAll('input');
    const valuesArr = [] as string[];

    inputs?.forEach(input => {
        valuesArr.push(input.value);
    })

    if (infoContainer && textInfoContainer) {
        container.classList.add('is-filled');
        infoContainer.classList.add('is-show');
        const valuesString = valuesArr.join(', ');
        textInfoContainer.textContent = valuesString;

        if (container.classList.contains('js-personal-info')) {
            personalInfo = valuesString;
        }

        if (container.classList.contains('js-shipping-address')) {
            shippingAddress = valuesString;
        }
    }
}

function openNextStep(el:HTMLElement) {
    const nextStepValue = el.dataset.nextStepTrigger;
    if (nextStepValue) {
        const nextStep = document.querySelector<Collapse>(`[data-next-step="${nextStepValue}"`);

        if (nextStep && !nextStep.getAttribute('collapsed')) {
            nextStep.toggleCollapse();
        }
    }
}

function closeThisStep(el:HTMLElement) {
    const collapse = el.closest('app-collapse') as Collapse;
    if (collapse) {
        collapse.toggleCollapse();
        getValues(collapse);
    }
}

function onClickNextStep(this: HTMLButtonElement) {
    const formOfStep = this.closest('.js-form-of-step') as HTMLFormElement;

    if (formOfStep) {
        const validator = createValidator(formOfStep, {});

        const isFormValid = validator.validate();
        if (isFormValid) {
            openNextStep(this);
            closeThisStep(this);
        }
    }
}

function onClickChangeStep(this: HTMLButtonElement) {
    const parentCollapse = this.closest<Collapse>('app-collapse');
    const allOrderCollapses = orderContainer ? orderContainer.querySelectorAll('app-collapse') : null;
    if (allOrderCollapses) {
        allOrderCollapses.forEach(el => {
            if (el !== parentCollapse && el.getAttribute('collapsed') == null) {
                el.toggleCollapse();
            }
        })
    }
}

function showSucessPopup() {
    const orderSuccessPopup = document.querySelector<LitPopupElement>('.js-order-success-popup');
    const personalInfoContainer = orderSuccessPopup?.querySelector('.js-success-order-personal-info');
    const addressContainer = orderSuccessPopup?.querySelector('.js-success-order-address-container');

    if (personalInfoContainer) {
        personalInfoContainer.textContent = personalInfo;
    }

    if (addressContainer) {
        addressContainer.textContent = shippingAddress;
    }
    orderSuccessPopup?.open();

    const bagCountInner = document.querySelector<HTMLElement>('.js-bag-count-inner');
    if (bagCountInner) bagCountInner.innerHTML = `
        <span class="js-bag-count"></span>
    `;

    const animation = createInitialOrderTimeline();
    animation.play();

    if(header) header.style.display = 'block';
}

function init(container: HTMLElement | Document = document) {
    orderContainer = container.querySelector('.js-order');
    const orderForm = orderContainer?.querySelector('.js-form-order');
    const nextStepTrigger = container.querySelectorAll('.js-next-step-trigger');
    const changeStepTrigger = container.querySelectorAll('.js-change-step');

    if (orderContainer && header) {
        header.style.display = 'none';
    }

    orderForm?.addEventListener('success-ordering', showSucessPopup);
    nextStepTrigger?.forEach(el => el.addEventListener('click', onClickNextStep));
    changeStepTrigger?.forEach(el => el.addEventListener('click', onClickChangeStep));
}

const _module = { init };

export default _module;
