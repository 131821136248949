import { Sketch } from './sketch.js';
import { webglObserver } from '../utils/execute-on-intersection';

let webglSketch: any;

export default function webglEffect(container: HTMLElement | Document = document) {
    const bannerArr = container.querySelectorAll<HTMLElement>('.js-banner');

    bannerArr.forEach(banner => {
        if (!banner.classList.contains('is-initialized-banner')) {
            webglObserver(
                banner,
                webglSketch,
                () => {
                    webglSketch = new Sketch({
                        element: banner,
                        duration: 1.4,
                        easing: 'easeOut',
                        autoplay: false,
                        hovered: true,
                        uniforms: {
                            width: {value: 0.5, type:'f', min:0, max:10},
                        }
                    });

                    banner.classList.add('is-initialized-banner');
                },
                { rootMargin: '200px 0px 200px 0px' },
            );
        }
    })

}
