import { fetchContentList, fetchContentListFilter } from '../dynamic-modules';
import serialize from 'form-serialize';
import revealAnimations from './reveal';
import { lazyload } from './lazy-load';
import bannerHover from './banner';
import delegate from 'delegate';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import { getOffsetTop } from '../utils/dom';

function init(container: HTMLElement | Document = document) {
    const catalogEl = document.querySelector<HTMLElement>('.js-catalog-content');

    if (catalogEl) {
        Promise.all([
            fetchContentList(),
            fetchContentListFilter()
        ])
            .then(([ContentList, ContentListFilter]) =>
            {
                const formSort = document.querySelector('.js-catalog-filter-sort');
                const formFilters = document.querySelector<HTMLFormElement>('.js-catalog-filters-form');
                const sortSelect = document.querySelector<HTMLSelectElement>('.js-catalog-sorting-select');
                const popupFilters = document.querySelector<LitPopupElement>('[data-lit-popup="catalog-filters"]');
                const popupSort= document.querySelector<LitPopupElement>('[data-lit-popup="catalog-sort"]');
                const filterEl = container.querySelector<HTMLElement>('.js-filter-element');
                const scrollToEl = document.querySelector('.js-scroll-to-catalog');
                const header = document.querySelector('.js-header');

                let isLoadMore = false;
                let offsetTop: number;
                if (scrollToEl) {
                    offsetTop = getOffsetTop(scrollToEl) + (header? - header.getBoundingClientRect().height : 0);
                }

                delegate(document, '.js-pagination-more', 'click', (e: Event) => {
                    e.preventDefault();
                    isLoadMore = true;

                });

                const filters = new ContentList(catalogEl, {
                    modules: [ContentListFilter],
                    pushState: true,
                    filter: {
                        el: filterEl,
                    },
                    onFetchStart: () => {
                        if (popupFilters?.classList.contains('lit-popup--opened')) {
                            formFilters?.classList.add('js-ajax-form--loading');
                        } else if (popupSort?.classList.contains('lit-popup--opened')) {
                            formSort?.classList.add('js-ajax-form--loading');
                        }

                        setTimeout(() => {
                            if (isLoadMore) {
                                const loadMore = document.querySelector('.js-pagination-more');
                                loadMore?.classList.add('is-loading');
                            }
                        }, 200);
                    },
                    onFetchSuccess: (instance, response) => {
                        const paginationContainer = instance.contentEl?.querySelector('.js-catalog-container-pagination');

                        if (response.data?.contentHtml && instance.contentEl) {
                            if (isLoadMore) {
                                paginationContainer?.remove();
                                instance.contentEl.innerHTML += response.data.contentHtml;
                                setTimeout(() => {
                                    isLoadMore = false;
                                    const loadMore = document.querySelector('.js-pagination-more');
                                    loadMore?.classList.remove('is-loading');
                                }, 200);
                            } else {
                                instance.contentEl.innerHTML = response.data.contentHtml;
                                window.scrollTo({ top: offsetTop, behavior: 'smooth' });
                            }

                            popupFilters?.close();
                            popupSort?.close();
                            revealAnimations.init();
                            lazyload.update();
                            bannerHover();

                            formFilters?.classList.remove('js-ajax-form--loading');
                            formSort?.classList.remove('js-ajax-form--loading');
                        }
                    },
                });

                if (formSort) {
                    formSort.addEventListener('submit', (e) => {
                        e.preventDefault();
                        setSort();
                    });
                    formSort.addEventListener('reset', (e) => {
                        e.preventDefault();
                        sortSelect.value = ''; // Установка значения по умолчанию для селектора сортировки
                        formSort.reset(); // Сброс всех фильтров
                        const sortFilter = formSort.querySelector<HTMLSelectElement>('.js-sort-filter:checked');
                        if (sortFilter) {
                            sortFilter.checked = false;
                        }
                        setSort(true); // Вызов setSort с параметром, указывающим на сброс
                    });

                    function setSort(isReset = false) {
                        const sortFilter = formSort.querySelector<HTMLSelectElement>('.js-sort-filter:checked');
                        if (sortFilter && !isReset) {
                            sortSelect.value = sortFilter.value;
                        }
                        setTimeout(() => {
                            if (formFilters && formSort && sortSelect) {
                                const formData = serialize(formFilters);
                                // const sortValue = sortSelect.value; // Получение текущего значения сортировки
                                let sign = isReset && !formData ? '' : (formData || sortSelect ? '?' : '');
                                const url = formFilters.action + sign + formData; // Конструирование URL
                                filters.fetchContent(url);
                            }
                        }, 10);
                    }
                }

                if (formFilters) {
                    formFilters.addEventListener('reset', (e) => {
                        e.preventDefault(); // Предотвращаем стандартное поведение сброса формы

                        // // Очистка всех полей формы
                        // formFilters.querySelectorAll('input[type=checkbox]').forEach(checkbox => {
                        //     checkbox.checked = false;
                        // });
                        // formFilters.querySelectorAll('input[type=text], input[type=number]').forEach(input => {
                        //     input.value = '';
                        // });
                        // formFilters.querySelectorAll('select').forEach(select => {
                        //     select.selectedIndex = 0;
                        // });
                        setTimeout(() => {
                            const formData = serialize(formSort);
                            console.log(serialize(formData));
                            // Составление URL без параметров запроса
                            let sign = formData ? '?' : '';
                            const url = formFilters.action + sign + formData; 
                            filters.fetchContent(url); // Вызываем загрузку контента без параметров

                            // Дополнительно, если нужно обновить URL в адресной строке браузера:
                            // if (history.pushState) {
                            //     history.pushState({ path: baseUrl }, '', baseUrl);
                            // }
                        }, 10);
                    });
                }
            });
    }
}

const _module = { init };

export default _module;
