export const fetchSwiper = () => import(/* webpackChunkName: "swiper" */ 'swiper');

export const fetchContentList = () =>
    import(/* webpackChunkName: "content-list" */ '@chipsadesign/content-list').then((m) => m.default);

export const fetchContentListFilter = () =>
    import(
        /* webpackChunkName: "content-list-filter" */ '@chipsadesign/content-list/dist/modules/ContentListFilter'
    ).then((m) => m.default);

export const fetchContentListSorting = () =>
    import(
        /* webpackChunkName: "content-list-sorting" */ '@chipsadesign/content-list/dist/modules/ContentListSorting'
    ).then((m) => m.default);

export const fetchContentListInfiniteScrolling  = () =>
    import(
        /* webpackChunkName: "content-list-scrolling" */ '@chipsadesign/content-list/dist/modules/ContentListInfiniteScrolling'
    ).then((m) => m.default);
