import { fetchSwiper } from '../dynamic-modules';

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-collections-slider')).forEach(async (el) => {
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

        if (slides.length > 2) {
            const { Swiper } = await fetchSwiper();

            const slider = new Swiper(el, {
                slidesPerView: 'auto',
                speed: 800,
                loop: true,
                centeredSlides: true,
                slideActiveClass: 'is-active',
            });

            if (slides.length < 3) {
                slider.loopDestroy()
            }
        }
    });
}

const _module = { init };

export default _module;
