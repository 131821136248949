import axios from 'axios';
import delegate from 'delegate';
import amountSet from './amount-set';
import { update } from './main-slider';
import select from './select';
// import basket from './basket';

function changeElement(event: any) {
    const cardParent = event.delegateTarget.closest('.js-card');
    const formContainer = cardParent?.querySelector('.js-card-form-inner');
    const imagesContainer = cardParent?.querySelector('.js-card-images-inner');
    const boxPrice = cardParent?.querySelector('.js-price');
    const potPrice = cardParent?.querySelector('.card-info__price-pot');
    const parent = event.delegateTarget.closest('.js-elements-container');
    const allSelects = Array.from(parent?.querySelectorAll('.js-element-trigger'));
    const colorSelect = allSelects.find(el => el.dataset.name == 'color') as HTMLInputElement;
    const sizeSelect = allSelects.find(el => el.dataset.name == 'size') as HTMLInputElement;
    const url = parent?.dataset.url;
    const data = {
        color: colorSelect.value,
        size: sizeSelect.value,
    }

    if (url) {
        axios.get(url, {
            params: data
        })
            .then(function (response) {
                if (formContainer && response.data.data.form) {
                    formContainer.innerHTML = response.data.data.form;
                    select.init();
                    amountSet.init();
                }
                if (imagesContainer && response.data.data.images) {
                    imagesContainer.innerHTML = response.data.data.images;
                    update();
                }
                if (boxPrice && response.data.data.box_price) {
                    boxPrice.innerHTML = response.data.data.box_price;
                    update();
                }
                if (potPrice && response.data.data.pot_price) {
                    potPrice.innerHTML = response.data.data.pot_price;
                    update();
                }
                // basket.init();
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.config);
                }
            });
    }
}


function init(container: HTMLElement | Document = document) {
    delegate(document, '.js-element-trigger', 'change', changeElement, true);
}

const _module = { init };

export default _module;
