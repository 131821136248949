let positions = { top: 0, left: 0, x: 0, y: 0 };

let bindMouseMoveHandler: (e: MouseEvent) => void;
let bindMouseUpHandler: (e: MouseEvent) => void;

const mouseMoveHandler = function (this: HTMLElement, e: MouseEvent) {
    const dx = e.clientX - positions.x;
    this.scrollLeft = positions.left - dx;
};


const mouseUpHandler = function (this: HTMLElement) {
    this.style.removeProperty('user-select');

    document.removeEventListener('mousemove', bindMouseMoveHandler);
    document.removeEventListener('mouseup', bindMouseUpHandler);
};

const mouseDownHandler = function (this: HTMLElement, e: MouseEvent) {
    this.style.userSelect = 'none';

    positions = {
        left: this.scrollLeft,
        top: this.scrollTop,
        x: e.clientX,
        y: e.clientY,
    };

    bindMouseMoveHandler = mouseMoveHandler.bind(this);
    bindMouseUpHandler = mouseUpHandler.bind(this);

    document.addEventListener('mousemove', bindMouseMoveHandler);
    document.addEventListener('mouseup', bindMouseUpHandler);
};

function init(container: HTMLElement | Document = document) {
    const scrollContainers = container.querySelectorAll<HTMLElement>('.js-drag-scroll');

    scrollContainers.forEach(el => {
        el.addEventListener('mousedown', mouseDownHandler);
    })
}

const _module = { init };

export default _module;
