import delegate from 'delegate';

const hoverableSelector = '.secton-error__collection-item';
const unhoverableSelector = '.js-no-hover-button';
const collectionItemSelector = '.secton-error__collection-item';

function Cursor() { 
    let clientX = 0;
    let clientY = 0;
    let cursorEl = document.querySelector<HTMLElement>('.js-cursor');
    function mouseEnter(event: any) {
        cursorEl?.classList.add('is-show');
        document.querySelector('body')?.classList.add('no-cursor');
    }

    function mouseLeave(event: any) {
        if (!event.target.parentElement.closest(hoverableSelector)) {
            cursorEl?.classList.remove('is-show');
        }
    }

        function getMouseCoords(event: MouseEvent) {
        clientX = event.clientX;
        clientY = event.clientY;
    }

    function mouseMove() {
        if (cursorEl) {
            cursorEl.style.left = `${clientX - cursorEl.offsetWidth/2}px`;
            cursorEl.style.top = `${clientY - cursorEl.offsetHeight/2}px`;
        }
    }

    function unhoverableEnter() {
        cursorEl?.classList.remove('is-show');
    }

    function unhoverableLeave() {
        cursorEl?.classList.add('is-show');
    }

    function branchToggle(e: Event) {
        let targetEl = e.target;
        targetEl.classList.toggle('branch-show');

    }

    function initHovers() {
        delegate(document, hoverableSelector, 'mouseenter', mouseEnter, true);
        delegate(document, unhoverableSelector, 'mouseenter', unhoverableEnter, true);
        delegate(document, unhoverableSelector, 'mouseleave', unhoverableLeave, true);
        delegate(document, hoverableSelector, 'mouseleave', mouseLeave, true);
        delegate(document, hoverableSelector, 'mousemove', mouseMove, true);
        delegate(document, collectionItemSelector, 'click', branchToggle, true);
    }

    document.addEventListener('mousemove', getMouseCoords, { passive: true });
    initHovers();
}



export { Cursor };
