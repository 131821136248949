import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';
import { LitPopupElement } from './custom-elements/LitPopupElement/LitPopupElement';

import headerTheme from './inits/header-theme';
import { lazyload } from './inits/lazy-load';
import firstBlock from './inits/first-block';
import menu from './inits/menu';
import splittedText from './animations/splitted-text';
import {createInitialBaseTimeline} from './animations/initial-base';
import { preloader } from './components/preloader';
import { timeout } from './utils/timeout';
import vhMobileFix from './../modules/vh-mobile-fix';

import revealAnimations from './inits/reveal';
import inputs from './inits/inputs';
import ajaxForms from './inits/ajax-forms';
import mainSlider from './inits/main-slider';
import infoSlider from './inits/info-slider';
import popularSlider from './inits/popular-slider';
import gallerySlider from './inits/gallery-slider';
import galleryPopup from './inits/gallery-popup';
import textPageSlider from './inits/text-page-slider';
import { Cursor } from './inits/custom-mouse';
import bannerHover from './inits/banner';
import collectionsSlider from './inits/collections-slider';
import catalogFilters from './inits/catalog-filters';
import select from './inits/select';
import amountSet from './inits/amount-set'
import { selector } from 'gsap';
import dragScroll from './inits/drag-scroll';
import orderInit from './inits/order';
// import bagCardInit from './inits/bag-card';
import basket from './inits/basket';
import cardSelects from './inits/card-selects';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    headerTheme.init();
    lazyload.update();
    revealAnimations.init();
    menu.init();
    firstBlock.init();
    splittedText.init();
    mainSlider();
    textPageSlider.init();
    Cursor();
    select.init();
    amountSet.init();
    basket.init();
    vhMobileFix();

    setTimeout(() => {
        window.scrollTo({top: 0});
    }, 100);

    // TODO Preloader catalog
    const windowUrl = window.location.pathname;
    const newWindowUrl = windowUrl .replace('/html/', '');
    const isMainPage = newWindowUrl == '/';
    const preloadLeaveTime = isMainPage ? 2500 : 100

    if (isMainPage) {
        preloader
    preloader
        .loadAssets()
        .then(() => timeout(preloadLeaveTime))
        .then(() => preloader.leave())
        .then(() => {
            const animation = createInitialBaseTimeline();
            animation.play();
            popularSlider.init();
            gallerySlider.init();
            galleryPopup.init();
            infoSlider();
            bannerHover();
            collectionsSlider.init();
            catalogFilters.init();
            dragScroll.init();
            orderInit.init();
            // bagCardInit.init();
            cardSelects.init();
            const subscribePopupEl = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="subscribe"]');
            if (subscribePopupEl) {
                ajaxForms.init(subscribePopupEl);
                inputs.init(subscribePopupEl);
            }

            const orderForm = document.querySelector<HTMLElement>('.js-order');
            if (orderForm) {
                ajaxForms.init(orderForm);
                inputs.init(orderForm);
            }
        });
    } else {
        preloader
            .loadAssets()
            .then(() => preloader.leave())
            .then(() => {
                const animation = createInitialBaseTimeline();
                animation.play();
                popularSlider.init();
                gallerySlider.init();
                galleryPopup.init();
                infoSlider();
                bannerHover();
                collectionsSlider.init();
                catalogFilters.init();
                dragScroll.init();
                orderInit.init();
                // bagCardInit.init();
                cardSelects.init();
                const subscribePopupEl = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="subscribe"]');
                if (subscribePopupEl) {
                    ajaxForms.init(subscribePopupEl);
                    inputs.init(subscribePopupEl);
                }

                const orderForm = document.querySelector<HTMLElement>('.js-order');
                if (orderForm) {
                    ajaxForms.init(orderForm);
                    inputs.init(orderForm);
                }
            });
    }
});

// Always at the end
module.hot?.accept();
