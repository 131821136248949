import SlimSelect from 'slim-select';
const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {
    const selectList = container.querySelectorAll<HTMLElement>('.js-dropdown');
    selectList.forEach((select) => {
        if (!select.classList.contains('select--initialization')) {
            const newselect = new SlimSelect({
                select: select,
                showSearch: false,
                placeholder: select.dataset.namePlaceholder,
                showContent: 'down',
                hideSelectedOption: true,
            });
            map.set(select, newselect);

            select.classList.add('select--initialization');

            select.addEventListener('update', () => {
                newselect.disable();
                newselect.enable();
            });
        }
    });

    let selectColors = container.querySelector<HTMLSelectElement>('.select-color');

    function setColorSelect() {
        let selectColors = container.querySelector<HTMLSelectElement>('.select-color');
        let optionsColor = selectColors?.querySelectorAll<HTMLOptionElement>('option');
        let selectedOption;
        if (optionsColor && selectColors?.length) {
            selectedOption = optionsColor[selectColors.selectedIndex];
        }
        let visibleSelectColor = container.querySelector<HTMLElement>('div.select-color');
        let optiomnsVisibleSelectColor = visibleSelectColor?.querySelectorAll<HTMLElement>('.ss-option');
        let placeholderVisibleSelectColor = visibleSelectColor?.querySelector<HTMLElement>('.placeholder');

        if (optionsColor?.length && optiomnsVisibleSelectColor?.length) {
            for (let i = 0; i < optionsColor.length; i++) {
                let spanText = `<span class="color-pin" style="background-color: ${optionsColor[i].getAttribute(
                    'data-color',
                )};"></span>`;

                if (!optiomnsVisibleSelectColor[i].querySelector('.color-pin')) {
                    optiomnsVisibleSelectColor[i].insertAdjacentHTML('afterbegin', spanText);
                }
            }
        }

        if (placeholderVisibleSelectColor) {
            if (!placeholderVisibleSelectColor.querySelector('.color-pin')) {
                placeholderVisibleSelectColor.insertAdjacentHTML(
                    'afterbegin',
                    ` <span class="color-pin" style="background-color: ${selectedOption?.getAttribute(
                        'data-color',
                    )};"></span>`,
                );
            }
        }
    }

    setTimeout(() => {
        setColorSelect();
    }, 1500);

    selectColors?.addEventListener('change', () => {
        setTimeout(() => {
            setColorSelect();
        }, 0);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-dropdown')).forEach((el) => {
        const select = map.get(el);

        if (select) {
            select.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
