import { fetchSwiper } from '../dynamic-modules';
import { executeOnIntersection } from '../utils/execute-on-intersection';
import { lazyload } from './lazy-load';

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-gallery-slider')).forEach(async (el) => {
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

        if (slides.length > 1) {
            const { Swiper, Pagination } = await fetchSwiper();

            executeOnIntersection(
                el,
                () => {
                    new Swiper(el, {
                        modules: [Pagination],
                        slidesPerView: 'auto',
                        speed: 800,
                        loop: true,
                        centeredSlides: true,
                        slideActiveClass: 'is-active',
                        slideToClickedSlide: true,
                        pagination: {
                            el: '.js-gallery-pagination',
                            type: "custom",
                            renderCustom: function (swiper, current, total) {
                                return current + '/' + total;
                            }
                        },
                        on: {
                            init: (swiper) => {
                                if (el.classList.contains('js-gallery-slider-popup')) {
                                    const mainSlider = document.querySelector<HTMLElement>('.js-gallery-slider-main');

                                    if (mainSlider && mainSlider.swiper) {
                                        const mainIndex = mainSlider.swiper.realIndex;
                                        swiper.slideToLoop(mainIndex, 0);

                                    }
                                }
                            },
                            slideChange: () => {
                                lazyload.update();
                            }
                        }
                    });
                },
                { rootMargin: '200px 0px 200px 0px' },
            );
        }
    });
}

const _module = { init };

export default _module;
