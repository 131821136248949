import axios from 'axios';

let addBusketBtn: HTMLElement | null;
let startDate: HTMLInputElement | null;
let endDate: HTMLInputElement | null;
let errorMessage: HTMLElement | null;
let wrapper: HTMLElement | null;

let id_i;

function removeErrorMessages() {
    const errorMessages = document.querySelectorAll('.error-message');
    errorMessages.forEach(errorMessage => errorMessage.remove());
}

async function basketAdd(this: any) {
    id_i = this.getAttribute('data-product-id');
    const url = this.closest('form')?.action;

    removeErrorMessages();

    // if (startDate && endDate && startDate.value !== '' && endDate.value !== '') {
    if (startDate && endDate) {
        try {
            const params = new URLSearchParams({
                product_id: id_i,
                start_date: startDate.value,
                end_date: endDate.value,
                action: 'add',
            });
            const response = await axios.get(url, {
                params: {
                    product_id: id_i,
                    start_date: startDate.value,
                    end_date: endDate.value,
                    action: 'add',
                },
            });

            // Обработка успешного ответа, например, переход на страницу оформления заказа
            window.location.href = `${url}?${params.toString()}`;
        } catch (error) {
            if (error && error.response && error.response.status === 422) {
                // Обработка ошибок валидации
                const validationErrors = error.response.data.errors;

                for (const field in validationErrors) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            validationErrors,
                            field,
                        )
                    ) {
                        const fieldErrors = validationErrors[field];

                        errorMessage = document.createElement('div');
                        errorMessage.className = 'error-message';
                        errorMessage.textContent = `${fieldErrors.join(
                            ', ',
                        )}`;
                        wrapper?.insertAdjacentElement(
                            'afterend',
                            errorMessage,
                        );
                    }
                }
            } else {
                // Обработка других ошибок
                console.error('Что-то пошло не так:', error);
            }
        }
    }
    // } else {
    //         errorMessage = document.createElement('div');
    //         errorMessage.className = 'error-message';
    //         errorMessage.textContent = 'Выберите даты';
    //         wrapper?.insertAdjacentElement('afterend', errorMessage);
    // }
}

function init(container: HTMLElement | Document = document) {
    addBusketBtn = container.querySelector<HTMLElement>(
        '.purchase-form__submit-btn',
    );
    startDate = container.querySelector<HTMLInputElement>('#start-date');
    endDate = container.querySelector<HTMLInputElement>('#end-date');
    wrapper = container.querySelector<HTMLSelectElement>(
        '.purchase-form__amount-wrapper',
    );

    addBusketBtn?.addEventListener('click', basketAdd);
}

const _module = { init };

export default _module;
